export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601687425/general/facebookLogoBlack.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601687425/general/instagramLogoBlack.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1653013257/cozen/menuButton.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/about2.webp'
export const aboutParallax =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1620855562/chosun/aboutParallax.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1653012013/cozen/contactbg.jpg'
export const foodGallery =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/gallery2.webp'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1653011901/cozen/hero.jpg'
export const heroText =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/heroText.webp'
export const logo =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/logo3.webp'
export const locationMap =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/map.webp'
export const mobileAbout =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileAbout2.webp'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1653015245/cozen/mobileFooter.jpg'
export const mobileHero =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileHero2.webp'
export const mobileMap =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileMap.webp'
export const mobileHeroText =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileHeroText2.webp'
export const mobilePromotion =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobilePromo2.webp'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1653013258/cozen/orderButton.png'
export const promotions =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/promo2.webp'
export const mobileGallery =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileGallery2.webp'
export const mobileLogo =
  'https://d24rddnpxh8ji7.cloudfront.net/saikosushi/mobileLogo.webp'

export { default as menu } from './cozenMenu.pdf'
