import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="105vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyBetween
        >
          <Header />
          <CFView textCenter column center mb="7%" w="100%">
            <CFImage
              w="90%"
              src={mobileHeroText}
              alt="Saiko Sushi hero text"
              zIndex={98}
            />
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25);"
          zIndex={90}
          column
        >
          <Header />
          <CFView column justifyCenter alignEnd mr="6%" h="100%" mt="15px">
            <CFImage
              w="70%"
              maxWidth="600px"
              src={heroText}
              alt="Saiko Sushi hero text"
              zIndex={98}
            />
            <CFView mt="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
